#login-container {
  background-image: url('../../../assets/images/login-bg.jpg');
  object-fit: cover;
  background-size: 100% 100%;
  height: 100vh;
  overflow-y: auto;
}

.w-35 {
  width: 35%;
}

/* .login-card {
  margin: 17.6vh 0vw !important;
} */
