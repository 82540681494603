.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.content {
  /* margin-top: 10px; */
  overflow-y: auto;
  padding: 24px;
  padding-top: 30px;
  min-height: 280px;
  background-image: url('../../../assets/images/login-bg-blur.png');
  transition: all 0.4s ease-in;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  -moz-background-size: cover;
  background-position-x: center;
  background-position-y: center;
}
.side-rd {
  border-radius: 0px 10px 10px 0px;
  -moz-border-radius: 0px 10px 10px 0px;
  -webkit-border-radius: 0px 10px 10px 0px;
}
.bg {
  background-color: #a8a8a7 !important;
}

.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  background-color: rgba(76, 175, 80, 0);
}

.ant-layout-sider-light .ant-layout-sider {
  border-radius: 0px 10px 10px 0px;
  -moz-border-radius: 0px 10px 10px 0px;
  -webkit-border-radius: 0px 10px 10px 0px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px solid red !important;
}
.overflow-y {
  overflow-y: auto;
}
