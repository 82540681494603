.nti {
  color: #d1d1d1;
}

span.ant-input-group.ant-input-group-compact.w-100.custom-dropdown {
  height: 70px;
}

.ant-select.w-100.custom-dropdown-select.ant-select-single.ant-select-show-arrow,
.ant-select-selector .ant-select-selection-search .ant-select-selection-item {
  height: 70px;
}

.custom-dropdown-select .ant-select-selector {
  height: 70px !important;
}

.w-80 {
  width: 80%;
}
