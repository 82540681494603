html,
body,
#root,
.App {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* 
#root {
  font-family: 'Roboto', sans-serif;
  height: 100%;
  width: 100%;
  overflow: visible !important;
} */

svg {
  vertical-align: baseline !important;
}
.true {
  background-color: green;
}
.false {
  background-color: red;
}

.mt-top-1vh {
  margin-top: 1vh !important;
}
