.nti {
  color: #d1d1d1;
}

.Subscribed {
  background-color: #22a052;
}

.Unsubscribed {
  background-color: #c22828;
}

.Completed {
  background-color: var(--quaternary-theme-color);
}

.Unpaid {
  background-color: #f6b608;
}

.Paid {
  background-color: #26b04a;
}

.Over {
  background-color: #c22828;
}

.Active {
  background-color: var(--tertiary-theme-color);
}
